import { useContext, useEffect, useState } from "react";
// import { UserInfoContext } from "../auth/user-validation";
// import { DailySchedule } from "./daily_schedule_bk";
import dayjs from "dayjs";
import { Tab, initTE, Input } from "tw-elements";
import { PeriodTime } from "./period-time";
import { WeekdayHeader } from "./weekday-header";
import { DailySchedule } from "./daily-schedule";
import { getMyClass, getMyDailySchedule, getMyWeeklySchedule, getClassDailySchedule, getClassWeeklySchedule } from "../../dal/schedule";
import { CourseSections } from "../../dal/course_sections";
import { UserInfoContext } from "../auth/user-validation";
import { Periods } from "../../dal/school_settings";

initTE({ Tab, Input });

function SchedulePage() {

  const ctx = useContext(UserInfoContext);

  const [myClasses, setMyClasses] = useState<any[]>([]) // 我的導師班
  const [selectedScheduleType, setSelectedScheduleType] = useState('0');  // 選擇課表類型
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))  // 選擇的日期

  const [periods, setPeriods] = useState(Periods);  // 一天八節課。或許不用儲存在 setState ?
  
  const [datesOfWeek, setDatesOfWeek] = useState<any[]>([]);  // 選擇的日期 currentDate 當週的每一天日期，用來產生課表的日期表頭列
  const [dailySchedule, setDailySchedule] = useState<any[]>([]);  // 當下報表類型的日課表
  const [weeklySchedule, setWeeklySchedule] = useState<any[]>([]);  // 當下報表類型的周課表
  
  const [courseSectionInfosByDate, setCourseSectionInfosByDate] = useState<any>({}) // 根據 datesOfWeek 裡每一天的課程分段，用來產生課表畫面。
  

  /** 0. 取得使用者資料，以及如果是教師，則取得我的班級資料，已完成課表類型的下拉選項 */
  useEffect( () => {
    const userInfo = ctx.userInfo ;
    console.log( { userInfo });
    if (userInfo?.roleType === 'teacher') {
      getMyClass().then(classes => {
        console.log({ classes: classes.class });
        setMyClasses(classes.class); 
      })
    }
  }, []);

  /** 1. 當課表類型變動時，日課表與周課表 */
  useEffect( ()=> {
    const p1 = new Promise(async ( resolve, rej) => { 
      try {
        const result: any[] = (selectedScheduleType === '0') ? (await getMyDailySchedule()) : (await getClassDailySchedule(selectedScheduleType));
        resolve(result);
      } catch ( ex) {
        rej(ex);
      }
    });
    const p2 = new Promise(async ( resolve, rej) => { 
      try {
        const result: any[] = (selectedScheduleType === '0') ? (await getMyWeeklySchedule()) : (await getClassWeeklySchedule(selectedScheduleType));
        resolve(result);
      } catch ( ex) {
        rej(ex);
      }
    });

    Promise.all([p1, p2]).then( values => {
      setDailySchedule(values[0] as any[]);
      setWeeklySchedule(values[1] as any[])
    })

  }, [selectedScheduleType])

  /** 2. 當 dailySchedule, 或 weeklySchedule 改變時， */
  useEffect(() => {
    const dtCurrent = dayjs(currentDate);

    // 2.1 則找出對應的所有日期
    const targetDates = parseWeekDays(dtCurrent.toDate());

    // 2.2 找出每個對應的日期的所有節次 
    const courseSectionInfos = mergeCourseSections(targetDates)
    
    console.log({ targetDates, courseSectionInfos });
    setDatesOfWeek([...targetDates]);
    setCourseSectionInfosByDate(courseSectionInfos);

  }, [currentDate, dailySchedule, weeklySchedule])


  /** 找出每個對應的日期的所有節次  */
  const mergeCourseSections = (targetDates: any[]) => {
    const crsSections = new CourseSections(dailySchedule as any[], weeklySchedule as any[]);
    const courseSectionInfos: any = {};
    
    targetDates.forEach( dt => {
      const dtText = dayjs(dt.date).format('YYYY-MM-DD');
      const sections = crsSections.getSectionsByDate(dtText);
      // console.log({ sections });
      courseSectionInfos[dtText] = sections ;
      // 2.3 判斷本日是否有課程
      const validSections = sections.filter( (sec: any) =>  (sec.dailyCourseSection || sec.weeklyCourseSection ))
      dt.hasSections = (validSections.length > 0)
    })

    return courseSectionInfos ;
  }


  /** 當使用者選了日曆上的日期 */
  const handleSelectDate = (date: any) => {
    console.log({ date, type: (typeof date) })

    const targetDate = dayjs(date);
    setCurrentDate(targetDate.format('YYYY-MM-DD'));

    const targetDates = parseWeekDays(date);
    const courseSectionInfos = mergeCourseSections(targetDates)
    setCourseSectionInfosByDate(courseSectionInfos);
  }

  /** 當使用者選取該週裡某一天時 */
  const handleShowCourseDate = (date: Date) => {
    console.log({ date })

    const targetDate = dayjs(date);
    setCurrentDate(targetDate.format('YYYY-MM-DD'));
  }

  /** 找出指定日期當週的所有日期 */
  const parseWeekDays = (date: any) => {
    const dj = dayjs(date);
    const currentWeekday = dj.day();  // 0: Sunday, 6: Saturday.
    const beginDateOfCurrentWeek = dj.subtract(currentWeekday, 'day')
    const result = []
    for (var i = 0; i < 7; i++) {
      const tempDj = beginDateOfCurrentWeek.add(i, 'day')
      result.push({ date: tempDj.toDate(), week_day: tempDj.day(), hasSections: false })
    }
    // console.log({ result });
    setDatesOfWeek(result)

    return result ;
  }

  /** 上一週 */
  const previousWeek = () => {
    console.log('previousWeek() ....')
    const targetDate = dayjs(currentDate).subtract(7, 'day');
    setCurrentDate(targetDate.format('YYYY-MM-DD'));

    const targetDates = parseWeekDays(targetDate.toDate());
    const courseSectionInfos = mergeCourseSections(targetDates)
    setCourseSectionInfosByDate(courseSectionInfos);
  }

  /** 下一週 */
  const nextWeek = () => {
    console.log('nextWeek() ....')
    const targetDate = dayjs(currentDate).add(7, 'day');
    setCurrentDate(targetDate.format('YYYY-MM-DD'));
    
    const targetDates = parseWeekDays(targetDate.toDate());
    const courseSectionInfos = mergeCourseSections(targetDates)
    setCourseSectionInfosByDate(courseSectionInfos);
  }

  const convertScheduleTypeText = (scheduleTypeNum: string): string => {
    return scheduleTypeNum === '0' ? 'teacher' : 'class'
  }

  useEffect(() => {
    handleSelectDate(currentDate) 
  }, [])



  return (
    <>
      <div className="containers p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 items-center">
          <div className="col-start-1 md:col-start-2 text-lg sm:text-xl bg-point mx-auto mb-5">課表查詢</div>
          <select id="small" className="block p-2 mb-5 color-p4 text-center w-64 mx-auto border border-[#9bb0d3]" value={selectedScheduleType} onChange={(e) => setSelectedScheduleType(e.target.value)}>
            <option value="0">{ctx.userInfo?.name}{ctx.userInfo?.roleType === 'teacher' ? '老師': ''} 的課表</option>
            { myClasses.map( cls => <option key={cls.classID} value="3">{cls.className}班級課表</option> )}
          </select>
        </div>

        {/* === Calendar === */}
        <div className="flex justify-center items-center gap-14 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className="cursor-pointer" onClick={previousWeek}>
            <path fill="currentColor" d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z" />
          </svg>
          <input type="date" className="w-[120px] p-0 shadow-none" value={currentDate} onChange={(e) => { setCurrentDate(e.target.value); handleSelectDate(dayjs(e.target.value).toDate()) }} />
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" className="cursor-pointer"  onClick={nextWeek}>
            <path fill="currentColor" d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z" /> 
          </svg>
        </div>

        <div className="w-full overflow-x-auto">
          <div className="min-w-1200">

            {/* Tabs navigation */}
            <ul className="flex justify-between gap-x-1 sm:gap-x-3 text-center text-sm sm:text-base mb-5 list-none border-b-2 border-gray-300 pl-0"
              role="tablist" data-te-nav-ref>
              {/*  周日無課  */}
              {datesOfWeek.map(dt => (
                <WeekdayHeader date={dt.date} onSelected={handleShowCourseDate} dateToShowCourse={dayjs(currentDate).toDate()} hasSections={dt.hasSections} />
              ))}
            </ul>

            {/* Tabs content */}
            <div className="flex justify-between gap-0 sm:gap-3 mb-6">
              <div className="sticky left-0 bg-white whitespace-nowrap shrink-0 w-auto me-3 sm:me-0">
                {periods.map(p => (
                  <PeriodTime period={p.period} begin_time={p.begin_time} end_time={p.end_time} /> 
                ))}

              </div>
              {/*  週日  */}
              {/*  <div class="hidden sm:block data-[te-tab-active]:block tabstyle text-white w-full overflow-hidden"
              style={{opacity: '100%'}} id="tabs-0" role="tabpanel" aria-labelledby="tabs-sun-tab">
              <div className="rounded-lg p-2 mb-3 h-[68px] bg-08">
              </div>
            </div>  */}
            {
              datesOfWeek.map(dt => {
                const dtTarget = dayjs(dt.date);
                const dtText = dtTarget.format('YYYY-MM-DD');
                const week_day = dtTarget.day();
                const sections = courseSectionInfosByDate[dtText];
                const validSections = sections ? sections.filter( (sec: any) => (sec.dailyCourseSection || sec.weeklyCourseSection )) : [];
                console.log( { dtText, week_day, dt, validSections })
                if ((week_day === 0 || week_day === 6) && validSections.length === 0) {
                  return ('');
                } else {
                  return (<DailySchedule weekday={week_day} target_date={dt.date} current_date={currentDate} sections={sections} scheduleType={convertScheduleTypeText(selectedScheduleType)} />);
                }
              })
            }
              {/*  週六  */}
              {/*  <div class="hidden sm:block data-[te-tab-active]:block tabstyle text-white w-full overflow-hidden"
              style={{opacity: '100%'}} id="tabs-6" role="tabpanel" aria-labelledby="tabs-sat-tab">
              <div className="rounded-lg p-2 mb-3 h-[68px] bg-04">
              </div>
            </div>  */}

              <div></div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default SchedulePage;