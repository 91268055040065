

export  async function getMyDailySchedule() {
    const resp = await fetch(`/service/devapi_schedule/my_daily_schedule`) ;
    return await resp.json();
}

export  async function getMyWeeklySchedule() {
    const resp = await fetch(`/service/devapi_schedule/my_weekly_schedule`) ;
    return await resp.json();
}

/** 取得這位教師的導師班級 */
export  async function getMyClass() {
    const resp = await fetch(`/service/devapi_jasmine/my_class`) ;
    return await resp.json();
}

/** 取得指定班級的日課表 */
export  async function getClassDailySchedule(class_id: string) {
    const resp = await fetch(`/service/devapi_schedule/class_daily_schedule/${class_id}`) ;
    return await resp.json();
}

export  async function getClassWeeklySchedule(class_id: string) {
    const resp = await fetch(`/service/devapi_schedule/class_weekly_schedule/${class_id}`) ;
    return await resp.json();
}
