import { Outlet } from "react-router-dom";
import UserValidation from "../components/auth/user-validation";
// import Header from "../components/header/header";
import NavBar from "../components/header/navbar";

/** 首頁框架 */
export default function Root() {
  return (
    <UserValidation>
      {/* <div id="header">
        <NavBar />
      </div> */}
      <div id="detail">
        <Outlet /> { /* child router 內容顯示處 */ }
      </div>
    </UserValidation>
  );
}
