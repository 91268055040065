import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../auth/user-validation";
import * as scheduleService from "../../dal/schedule";
import dayjs from "dayjs";

export function PeriodTime(prop: any) {

  // const { userInfo } = useContext(UserInfoContext)

  // console.log(({ userInfo })); 

  const currentTime = dayjs().format('HH:mm');
  const isNow = (currentTime >= prop.begin_time && currentTime <= prop.end_time )

  return (
    <div className={ `flex justify-end items-center h-[68px] mb-3 p-1 rounded-lg ${ isNow ? " bg-[#fdd5c8]" : ""} `}>
      <div className="text-5xl font-semibold me-2">{prop.period}</div>
      <div>
        <div>{prop.begin_time}</div>
        <div>{prop.end_time}</div> 
      </div>
    </div>
  )
}