

export const Periods = [
    { period: "1", begin_time: "08:00", end_time: "08:50" },
    { period: "2", begin_time: "09:00", end_time: "09:50" },
    { period: "3", begin_time: "10:00", end_time: "10:50" },
    { period: "4", begin_time: "11:00", end_time: "11:50" },
    { period: "5", begin_time: "13:00", end_time: "13:50" },
    { period: "6", begin_time: "14:00", end_time: "14:50" },
    { period: "7", begin_time: "15:00", end_time: "15:50" },
    { period: "8", begin_time: "16:00", end_time: "16:50" }
  ]

  export const WeekDays = [
    { day: 0, text: '日' },
    { day: 1, text: '一' },
    { day: 2, text: '二' },
    { day: 3, text: '三' },
    { day: 4, text: '四' },
    { day: 5, text: '五' },
    { day: 6, text: '六' }
  ]
