import dayjs from "dayjs";
import { calculateBgColor } from "./bg_color";
import { Periods, WeekDays } from "./school_settings";


/** 代表畫面上一節課程的資料內容 */
export class CourseSectionInfo {

    private _week_day: number = 0;
    private _period: number = 0;

    constructor( public dailyCourseSection: any, public weeklyCourseSection: any, week_day: number, period: number) {
        this._week_day = week_day;
        this._period = period ;
    }

    get schoolYear(): number {
        return (this.dailyCourseSection ? this.dailyCourseSection.schoolYear : this.weeklyCourseSection.schoolYear) ;
    }
    get semester(): number {
        return (this.dailyCourseSection ? this.dailyCourseSection.semester : this.weeklyCourseSection.semester) ;
    }
    get subject(): string {
        return (this.dailyCourseSection ? this.dailyCourseSection.subject : this.weeklyCourseSection.subject) ;
    }
    get subjLevel(): string {
        return (this.dailyCourseSection ? this.dailyCourseSection.subjLevel : this.weeklyCourseSection.subjLevel) ;
    }
    get courseID(): number {
        return (this.dailyCourseSection ? this.dailyCourseSection.courseID : this.weeklyCourseSection.courseID) ;
    }
    get courseName(): string {
        return (this.dailyCourseSection ? this.dailyCourseSection.courseName : this.weeklyCourseSection.courseName) ;
    }
    get classroomID(): number {
        return (this.dailyCourseSection ? this.dailyCourseSection.classroomID : this.weeklyCourseSection.classroomID) ;
    }
    get classroomName(): string {
        return (this.dailyCourseSection ? this.dailyCourseSection.classroomName : this.weeklyCourseSection.classroomName) ;
    }
    get classID(): number {
        return (this.dailyCourseSection ? this.dailyCourseSection.classID : this.weeklyCourseSection.classID) ;
    }
    get className(): string {
        return (this.dailyCourseSection ? this.dailyCourseSection.className : this.weeklyCourseSection.className) ;
    }
    get credit(): number {
        return (this.dailyCourseSection ? this.dailyCourseSection.credit : this.weeklyCourseSection.credit) ;
    }
    get weekday(): number {
        return this._week_day ;
    }
    get period(): number {
        return this._period ;
    }
    get teacherNames(): string[] {
        return (this.dailyCourseSection ? this.dailyCourseSection.teacherName : this.weeklyCourseSection.teacherName) ;
    }

    get bgColor(): string {
        return calculateBgColor(this.subject);
    }
}


export class CourseSections {

    private _aryDailySections: any[] = [];
    private _dicDailySections: any = {};

    private _aryWeeklySections: any[] = [];
    private _dicWeeklySections: any = {};

    private _periods = Periods
    private _week_days: any[] = WeekDays;

    constructor(public dailyCourseSections: any[], public weeklyCourseSections: any[]) {

        this._aryDailySections = dailyCourseSections ;
        this._aryDailySections.forEach( sec => {
            const keyDaily = `${dayjs(sec.occurDate).format('YYYY-MM-DD')}_${sec.period}`;
            this._dicDailySections[keyDaily] = sec ;
        });

        this._aryWeeklySections = weeklyCourseSections ;
        this._aryWeeklySections.forEach( sec => {
            const keyWeekly = `${this.convertDayToText(sec.weekday)}_${sec.period}`;
            this._dicWeeklySections[keyWeekly] = sec ;
        });
    }

    convertDayToText(day: number) {
        if (day === 0) { return "日" ; }
        if (day === 1) { return "一" ; }
        if (day === 2) { return "二" ; }
        if (day === 3) { return "三" ; }
        if (day === 4) { return "四" ; }
        if (day === 5) { return "五" ; }
        if (day === 6) { return '六' ; }
        return ''
    }


    /** 找出指定日期當天的所有節次。 所有節次都會回傳，要以 subject 來確認是否有課程。*/
    getSectionsByDate(targetDate: string): CourseSectionInfo[] {
        
        const dt = dayjs(targetDate);
        const dtText = dt.format('YYYY-MM-DD');
        const week_dayText = this.convertDayToText(dt.day());

        const result: CourseSectionInfo[] = this._periods.map( p => {
            const keyDaily = `${dtText}_${p.period}`;
            const keyWeekly = `${week_dayText}_${p.period}`;
            const crsSec = new CourseSectionInfo( this._dicDailySections[keyDaily], this._dicWeeklySections[keyWeekly], dt.day(), parseInt(p.period, 10));
            return crsSec ;
        });

        return result ;
    }

}