import { useContext } from "react";
import { UserInfoContext } from "./auth/user-validation";
// import { DailyScheduleTeacher } from "./schedule/daily_schdule_teacher";
// import { DailyScheduleStudent } from "./schedule/daily_schedule_student";
// import { DailyScheduleParent } from "./schedule/daily_schedule_parent";
// import { DailySchedule } from "./schedule/daily_schedule_bk";
import { Link } from "react-router-dom";


function MainPage() {

    const { userInfo } = useContext(UserInfoContext)

    return(
        <div className="p-8 flex justify-center h-screen">
            <Link to="/schedule">課表查詢</Link>
            {/* <p>姓名：{ userInfo && userInfo.name } ({ userInfo && userInfo.account })</p>
            <p>學校：{ userInfo && userInfo.schoolName } ({ userInfo && userInfo.schoolDsns })</p>
            <p>學年期：{ userInfo && userInfo.schoolYear } ({ userInfo && userInfo.semester })</p>
            <p>身份：{ userInfo && userInfo.roleType }</p> */}
            {/* {
                (userInfo?.roleType === 'teacher') ? <DailyScheduleTeacher /> : (  (userInfo?.roleType === 'student') ? <DailyScheduleTeacher /> : <DailyScheduleTeacher /> )
            } */}
            {/* <DailySchedule /> */}
        </div>
    )
}

export default MainPage;